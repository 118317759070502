var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Dashboard")])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "align-baseline": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "with-border",
                            attrs: {
                              items: _vm.departments,
                              "item-value": "id",
                              "item-text": "name",
                              "hide-details": "",
                              placeholder: "Afdelingen",
                              clearable: "",
                              multiple: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    index === 0
                                      ? _c("span", [
                                          _vm._v(
                                            "Afdeling (" +
                                              _vm._s(
                                                _vm.selectedDepartments.length
                                              ) +
                                              ")"
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedDepartments,
                              callback: function($$v) {
                                _vm.selectedDepartments = $$v
                              },
                              expression: "selectedDepartments"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "dialog",
                              attrs: {
                                "close-on-content-click": false,
                                lazy: "",
                                "nudge-right": "100",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "input input-date",
                                attrs: {
                                  slot: "activator",
                                  placeholder: "DD-MM-YYYY",
                                  label: "Datum",
                                  "error-messages": _vm.date.errorMessage
                                },
                                slot: "activator",
                                model: {
                                  value: _vm.date.formattedDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.date, "formattedDate", $$v)
                                  },
                                  expression: "date.formattedDate"
                                }
                              }),
                              _c("v-date-picker", {
                                ref: "picker",
                                attrs: {
                                  "first-day-of-week": "1",
                                  locale: "nl-nl",
                                  min: "1910-01-01",
                                  color: "#837f16"
                                },
                                on: { change: _vm.emitSelectedDate },
                                model: {
                                  value: _vm.date.selectedDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.date, "selectedDate", $$v)
                                  },
                                  expression: "date.selectedDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel" },
              [
                _vm.$store.state.Auth.organization &&
                _vm.$store.state.Auth.organization.id &&
                _vm.selectedDate
                  ? _c("DashboardBureau", {
                      attrs: {
                        organization: _vm.$store.state.Auth.organization.id,
                        departments: _vm.selectedDepartments,
                        date: _vm.selectedDate,
                        showOperationalKpis: true
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }