import { Component, Vue } from 'vue-property-decorator';
import DashboardBureau from '@/components/dashboard-bureau/DashboardBureau.vue';
import { DateFormatter } from '@/support/DateFormatter';
import { DateTime } from 'luxon';
import { Department } from '@/models/Department';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';

@Component<Dashboard>({
  components: {
    DashboardBureau,
  },
})
export default class Dashboard extends Vue {
  public $pageTitle = 'Dashboard';

  protected isLoading = true;

  // dates
  protected date = new DateFormatter();

  protected selectedDate: DateTime | null = null;

  protected departments: Department[] = [];

  protected selectedDepartments: string[] = [];

  protected mounted() {
    this.date.selectedDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.initBreadcrumb();
    this.emitSelectedDate();
    this.initialize();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Dashboard' },
        ],
      });
  }

  protected async getDepartments() {
    this.departments = await new Department()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async initialize() {
    await this.getDepartments();
    this.isLoading = false;
  }

  protected emitSelectedDate() {
    this.selectedDate = DateTime.fromSQL(this.date.selectedDate as string);
  }
}
